import axios from "axios";
import { getUserInfo, logout } from "./auth";

export const url = process.env.REACT_APP_URL
  ? process.env.REACT_APP_URL
  : "http://localhost:5010";
export const frontendDomain = process.env.REACT_APP_DOMAIN
  ? process.env.REACT_APP_DOMAIN
  : "localhost";
export const recaptchaKey = "6LezM2EcAAAAAEaPQMCrQ4OJA9vDcubRNvk7Bt1h";

const api = axios.create({
  baseURL: `${url}/`,
});

api.interceptors.request.use(async (config) => {
  const access_token = getUserInfo().access_token;
  if (access_token) {
    config.headers.Authorization = `Bearer ${access_token}`;
  }
  return config;
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (!axios.isCancel(error)) {
      if (error.response.status === 401) {
        const token = getUserInfo().access_token;
        if (token) {
          logout();
          return
        }
      }
    }

    return Promise.reject(error);
  }
);


export default api;
