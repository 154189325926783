import TextField from "@material-ui/core/TextField";
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import SubmitButton from "../../components/SubmitButton";
import api from '../../services/api';
import { notifyError, notifyUser } from '../../utils';


export default function AccountRecover() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      email: email
    }

    try {
      await api.post('users/recover/account', data);
      notifyUser("Te enviamos um email com instruções sobre como proceder.", "email", "success");
      history.push('/');
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  }

  return (
    <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh">
      <span className="color-tertiary bold size-xxxlarge">LabLift</span>
      <p className="size-small">Recupere sua conta.</p>
      <form
        className="padding-medium flex-column content-box margin-bottom-small"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Seu email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <SubmitButton
          standardButton={<button className="margin-top-medium button-tertiary" type="submit">Enviar</button>}
          loadingButton={<button className="flex-row flex-crossaxis-center margin-top-medium button-tertiary-disabled" value="click" disabled>Loading...</button>}
          isLoading={loading}
        />
      </form>
      <p>Lembrou suas credenciais? <Link className="underline" to="/login">Faça login!</Link></p>
    </div>
  )
}