import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function Index() {
  const history = useHistory();

  useEffect(() => {
    history.replace("/login")
  });

  return (
    <></>
  );
}
