import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import TextField from "@material-ui/core/TextField";

import api from "../../services/api";
import { setUserInfo } from "../../services/auth";
import { userIsAuthenticated } from "../../services/auth";
import { notifyError } from "../../utils";
import { Link } from "react-router-dom";
import queryString from 'query-string';

import CircularProgress from '@material-ui/core/CircularProgress';
import SubmitButton from "../../components/SubmitButton";

export default function Login() {
  const location = useLocation();
  const continueLink = queryString.parse(location.search).continue
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const authorizedRedirectDomains = ["lablift.com.br", "localhost"]
  const standardRedirect = "https://lablift.com.br"

  const isUrl = string => {
    try { return Boolean(new URL(string)); }
    catch (e) { return false; }
  }

  function redirect() {
    if (continueLink && isUrl(continueLink)) {
      const { hostname } = new URL(continueLink);
      if (authorizedRedirectDomains.includes(hostname.split(".").slice(-3).join("."))) {
        window.location.assign(continueLink);
        return
      }
    }
    window.location.assign(standardRedirect);
  }

  useEffect(() => {
    const firstLoad = () => {
      if (userIsAuthenticated()) {
        redirect()
      } else {
        setLoaded(true);
      }
    }
    firstLoad();
  });

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await api.post("login", data);
      setUserInfo(response.data);
      redirect()
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  }

  return (
    !loaded ?
      <div className="flex-row flex-axis-center flex-crossaxis-center height-100vh"><CircularProgress /></div> :
      <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh">
        <span className="color-tertiary bold size-xxxlarge">LabLift</span>
        <p className="size-small">Preencha seus dados</p>
        <form
          className="padding-medium flex-column content-box margin-bottom-small"
          onSubmit={handleSubmit}
        >
          <TextField
            required
            label="Usuário"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            required
            className="margin-top-small"
            type="password"
            label="Senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <SubmitButton
            standardButton={<button className="margin-top-medium button-tertiary" type="submit">Login</button>}
            loadingButton={<button className="flex-row flex-crossaxis-center margin-top-medium button-tertiary-disabled" value="click" disabled>Loading...</button>}
            isLoading={loading}
          />
        </form>
        <p>Ainda não é registrado? <Link className="underline" to="/register">Registre-se agora!</Link></p>
        <p><Link className="underline" to="/recover">Esqueci minha senha</Link></p>
        <p><Link className="underline" to="/confirmation/resend">Chave de confirmação do email</Link></p>
      </div>
  );
}
