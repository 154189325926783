import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@material-ui/core/TextField";

import api, { recaptchaKey } from '../../services/api';
import { notifyError } from '../../utils';
import SubmitButton from '../../components/SubmitButton';

export default function Register() {
  const history = useHistory();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [blockSubmit, setBlockSubmit] = useState(true);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      name: name,
      email: email,
      username: username,
      password: password
    }

    try {
      await api.post('users', data);
      history.push({ pathname: '/confirmation', state: { name: name, username: username, email: email } });
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  }

  return (
    <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh">
      <span className="color-tertiary bold size-xxxlarge">LabLift</span>
      <p className="size-small">Preencha seus dados</p>
      <form
        className="padding-medium flex-column content-box margin-bottom-small"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Nome"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          required
          className="margin-top-small"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          className="margin-top-small"
          label="Usuário"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          required
          className="margin-top-small"
          type="password"
          label="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="margin-very-small margin-top-small margin-bottom-small">
          <ReCAPTCHA
            sitekey={recaptchaKey}
            onChange={() => setBlockSubmit(!blockSubmit)}
          />
        </div>

        {
          blockSubmit ?
            <button className="button-tertiary-disabled" value="click" disabled>Registrar</button> :
            <SubmitButton
              standardButton={<button className="button-tertiary" type="submit">Registrar</button>}
              loadingButton={<button className="flex-row flex-crossaxis-center button-tertiary-disabled" value="click" disabled>Loading...</button>}
              isLoading={loading}
            />
        }
      </form>
      <p>Já é registrado? <Link className="underline" to="/login">Faça login!</Link></p>
    </div>


  )
}