import jwt_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import { frontendDomain } from "./api";

export const isExpired = (token) => {
  let decoded_token = jwt_decode(token);
  let now = Date.now();
  return now >= decoded_token.exp * 1000;
};

export const userIsAuthenticated = () => {
  const token = getUserInfo().access_token;
  if (token && !isExpired(token)) {
    return true;
  }
  return false;
};

export function getUserInfo() {
  const cookies = new Cookies();
  const user = cookies.get('user');
  if (user) {
    return user;
  } else {
    return {};
  }
}

export function setUserInfo(user = {}) {
  let expires = new Date(jwt_decode(user.access_token).exp * 1000);
  const cookies = new Cookies();
  cookies.set('user', user, { domain: frontendDomain, sameSite: "strict", expires: expires, secure: true });
  return true;
}

export function logout(redirect = true) {
  const cookies = new Cookies();
  cookies.remove("user", { domain: frontendDomain});
  if (redirect) {
    window.location.href = "/login";
  }
}
