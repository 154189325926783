import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Confirmation from "./pages/Confirmation";
import Login from "./pages/Login";
import Index from "./pages/Index";
import Register from "./pages/Register";
import ConfirmationResend from "./pages/ConfirmationResend";
import ConfirmationSubmit from "./pages/ConfirmationSubmit";
import AccountRecover from "./pages/AccountRecover";
import AccountRecoverSubmit from "./pages/AccountRecoverSubmit";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Index} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/confirmation" exact component={Confirmation} />
        <Route path="/confirmation/resend" component={ConfirmationResend} />
        <Route path="/confirmation/submit" component={ConfirmationSubmit} />
        <Route path="/recover" exact component={AccountRecover} />
        <Route path="/recover/submit" component={AccountRecoverSubmit} />
      </Switch>
    </BrowserRouter>
  );
}
