import React, { useState } from 'react';
import TextField from "@material-ui/core/TextField";
import { Link, useHistory, useLocation } from 'react-router-dom';
import api from '../../services/api';
import { notifyError, notifyUser } from '../../utils';
import queryString from 'query-string';
import SubmitButton from '../../components/SubmitButton';


export default function AccountRecoverSubmit() {
  const history = useHistory();
  let location = useLocation();
  const key = queryString.parse(location.search).key
  const username = queryString.parse(location.search).username
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e) {
    setLoading(true);
    e.preventDefault();

    const data = {
      username: username,
      password: password,
      key: key
    }

    try {
      await api.put('users/recover/account', data);
      notifyUser(`Senha alterada com sucesso.`, "email", "success")
      history.push('/');
    } catch (error) {
      setLoading(false);
      notifyError(error);
    }
  }

  return (
    <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh">
      <span className="color-tertiary bold size-xxxlarge">LabLift</span>
      <p className="size-small">Defina sua nova senha.</p>
      <form
        className="padding-medium flex-column content-box margin-bottom-small"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          className="margin-top-small"
          type="password"
          label="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <SubmitButton
          standardButton={<button className="margin-top-medium button-tertiary" type="submit">Enviar</button>}
          loadingButton={<button className="flex-row flex-crossaxis-center margin-top-medium button-tertiary-disabled" value="click" disabled>Loading...</button>}
          isLoading={loading}
        />
      </form>
      <p>Lembrou suas credenciais? <Link className="underline" to="/login">Faça login!</Link></p>
    </div>
  )
}