import TextField from "@material-ui/core/TextField";
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

export default function Confirmation(props) {
  const history = useHistory();
  const [key, setKey] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    history.push(`/confirmation/submit?username=${props.location.state.username}&key=${key}`)
  }

  return (
    <div className="flex-column flex-axis-center flex-crossaxis-center min-height-100vh">
      <span className="color-tertiary bold size-xxxlarge">LabLift</span>
      <p className="size-small">Forneça a chave de confirmação enviada para {props.location.state.email}</p>
      <form
        className="padding-medium flex-column content-box margin-bottom-small"
        onSubmit={handleSubmit}
      >
        <TextField
          required
          label="Código"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
        <button className="button-tertiary margin-top-small" type="submit">Enviar</button>
      </form>
      <p className="margin-top-small"><Link className="underline" to="/confirmation/resend">Reenviar chave de confirmação</Link></p>
      <p>Já confirmou? <Link className="underline" to="/login">Faça login!</Link></p>
    </div>
  )
}